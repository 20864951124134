import DisplayConnection from "./components/Display/DisplayConnection";
import axios from "./config/axios";
import { useEffect, useState } from "react";
import { handleAPIError } from "./utils/helpers";
import live_display_socket from "./config/live_display_socket";
import moment from "moment-timezone";
import { HiWifi } from "react-icons/hi2";
import ResortTV from "./components/Display/ResortTV";
import VacationCountdown from "./components/Display/VacationCountdown";
import SingleRideWaitTime from "./components/Display/SingleRideWaitTime";
import ScrollRideWaitTime from "./components/Display/ScrollRideWaitTime";
import Home from "./components/Display/Home";
import AnaheimResortTv from "./components/Display/AnaheimResortTv";
import BottomBar from "./components/Display/BottomBar";
import GetStarted from "./components/Display/GetStarted";
import Stream from "./components/Display/Stream";
import { useConfig } from "./hooks/useConfig";
function App() {
  // we import the config from firebase
  // it ensures that the config is loaded before the app starts
  const { config } = useConfig();

  const [deviceID, setDeviceID] = useState(null);
  const [device, setDevice] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState("active");

  const [countDownDate, setCountDownDate] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [started, setStarted] = useState(false);
  const [currentRide, setCurrentRide] = useState(null);
  const [refresh, setRefresh] = useState(0);

  const getDeviceData = (device_id) => {
    axios
      .get(`/users/device/${device_id}`, { withCredentials: true })
      .then((response) => {
        if (response.data.device_found) {
          // console.log(response.data.device);
          setDevice(response.data.device);
          setSubscriptionStatus(response.data.device.subscription.status);
          // setRefresh(Math.random());
        } else {
          setDevice(null);
          localStorage.removeItem("device_data");
          // localStorage.setItem("memClear", "yes"); // NOTE: This line caused the refresh issue. It shouldn't be here
          window.location.reload();
        }
      })
      .catch((error) => {
        handleAPIError(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    try {
      console.log("v13-fb-config");
      if (localStorage.getItem("memClear")) {
        localStorage.removeItem("memClear");
        setStarted(true);
      }

      if (!localStorage.getItem("volume")) {
        localStorage.setItem("volume", 0.3);
      }

      const saved_device_data = JSON.parse(localStorage.getItem("device_data"));
      const dId = saved_device_data._id;

      if (dId) {
        live_display_socket.on("connect", () => {
          live_display_socket.emit("display_join", {
            device_id: dId,
          });

          // console.log("live display socket connected and listening for refresh");
          // live_display_socket.off("display_refresh");

          live_display_socket.on("display_refresh", () => {
            console.log("refresh event");
            getDeviceData(dId);
          });
        });

        live_display_socket.on("disconnect", () => {
          live_display_socket.off("display_refresh");
        });

        live_display_socket.on("connect", () => {
          console.log("live display socket connected");
          live_display_socket.emit("sub_info_subscribe", { device_id: dId });
        });

        live_display_socket.on("sub_info_update", (data) => {
          setCountDownDate(data.cf_disney_vacation_countdown);
          setLastName(data.last_name);
          setTimezone(data.cf_select_your_time_zone.split("-")[0]);
          let tz = data.cf_select_your_time_zone.split("-")[0];
          if (tz === "EST" || tz === "EDT") {
            tz = "EST5EDT";
          } else if (tz === "PST" || tz === "PDT") {
            tz = "PST8PDT";
          } else if (tz === "CST") {
            tz = "CST6CDT";
          } else if (tz === "MST") {
            tz = "MST7MDT";
          }
          setTimezone(tz);

          setCurrentDate(moment().tz(tz).format("YYYY-MM-DD"));

          // console.log(data.subscription_status);

          setSubscriptionStatus(data.subscription_status);
        });
      }
    } catch (error) {
      console.log(error);
    }

    return () => {
      live_display_socket.emit("sub_info_unsubscribe", {
        device_id: deviceID,
      });
    };
  }, [deviceID]);

  useEffect(() => {
    try {
      if (localStorage.getItem("device_data")) {
        // console.log('device data found')
        const saved_device_data = JSON.parse(
          localStorage.getItem("device_data")
        );
        setDeviceID(saved_device_data._id);
        getDeviceData(saved_device_data._id);
      }
    } catch (error) {
      // console.log(error);
    }
  }, [refresh]);

  useEffect(() => {
    if (deviceID) {
      // live_display_socket.on("connect", () => {
      //   console.log("live display socket connected");
      //   live_display_socket.emit("sub_info_subscribe", { device_id: deviceID });
      // });
      // live_display_socket.on("sub_info_update", (data) => {
      //   setCountDownDate(data.cf_disney_vacation_countdown);
      //   setLastName(data.last_name);
      //   setTimezone(data.cf_select_your_time_zone.split("-")[0]);
      //   let tz = data.cf_select_your_time_zone.split("-")[0];
      //   if (tz === "EST" || tz === "EDT") {
      //     tz = "EST5EDT";
      //   } else if (tz === "PST" || tz === "PDT") {
      //     tz = "PST8PDT";
      //   } else if (tz === "CST") {
      //     tz = "CST6CDT";
      //   } else if (tz === "MST") {
      //     tz = "MST7MDT";
      //   }
      //   setTimezone(tz);
      //   setCurrentDate(moment().tz(tz).format("YYYY-MM-DD"));
      //   // console.log(data.subscription_status);
      //   setSubscriptionStatus(data.subscription_status);
      // });
      // check if live display socket is disconnected
      // live_display_socket.on("disconnect", () => {
      //   console.warn("live display socket disconnected");
      // });
      // return () => {
      //   live_display_socket.emit("sub_info_unsubscribe", {
      //     device_id: deviceID,
      //   });
      // };
    }
  }, [deviceID]);

  const goToWifiSettingsPageOnExpoApp = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "open-wifi-settings",
      })
    );
  };

  return (
    <div style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
      <>
        {(!deviceID || !started) && (
          <div
            style={{
              width: "auto",
              height: "80px",
              background: "white",
              borderRadius: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "25px",
              position: "absolute",
              right: "5%",
              top: "5%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 5,
              fontFamily: "Lato",
            }}
            onClick={goToWifiSettingsPageOnExpoApp}
          >
            <HiWifi
              style={{ fontSize: "30px", marginRight: "10px" }}
              color={"blue"}
            />
            Wifi Settings
          </div>
        )}

        {!started && deviceID ? (
          <GetStarted setStarted={setStarted} />
        ) : !deviceID ? (
          <DisplayConnection
            setRefresh={setRefresh}
            started={started}
            setStarted={setStarted}
            deviceID={deviceID}
          />
        ) : (
          <>
            {/*<span style={{fontSize: '200px', color: 'black'}}>Connected {localStorage.getItem('device_data') ? 'data found' : 'data not found'}</span>*/}
            {deviceID && device && (
              <>
                {subscriptionStatus === "active" ||
                subscriptionStatus === "non_renewing" ? (
                  <>
                    <BottomBar device={device} />

                    {device.screen_view === "Home" && (
                      <Home
                        countDownDate={countDownDate}
                        timezone={timezone}
                        currentDate={currentDate}
                        device={device}
                      />
                    )}

                    {device.screen_view === "TRC" && <Stream device={device} />}

                    {device.screen_view === "RTV__Orlando" && (
                      <ResortTV device={device} userTz={timezone} />
                    )}

                    {device.screen_view === "RTV__Anaheim" && (
                      <AnaheimResortTv device={device} userTz={timezone} />
                    )}

                    {device.screen_view === "VC" && (
                      <VacationCountdown
                        countDownDate={countDownDate}
                        currentDate={currentDate}
                        timezone={timezone}
                        device={device}
                        last_name={lastName}
                      />
                    )}

                    {device.screen_view === "AutoScroll" && (
                      <ScrollRideWaitTime
                        device={device}
                        setCurrentRide={setCurrentRide}
                      />
                    )}

                    {device.screen_view.includes("Ride__") ||
                    (device.screen_view === "AutoScroll" && currentRide) ? (
                      <SingleRideWaitTime
                        device={device}
                        device_id={deviceID}
                        ride_id={
                          device.screen_view === "AutoScroll"
                            ? currentRide
                            : device.screen_view.split("__")[1]
                        }
                        setCurrentRide={setCurrentRide}
                      />
                    ) : (
                      <span></span>
                    )}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100vh",
                      fontFamily: "Lato",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "50px",
                        fontWeight: "700",
                      }}
                    >
                      Subscription Inactive
                    </span>
                    <span
                      style={{
                        fontSize: "40px",
                        textAlign: "center",
                        padding: "0 40px",
                      }}
                    >
                      Please visit {subscriptionStatus}{" "}
                      <span style={{ color: "blue" }}>
                        waitsigns.com/portal
                      </span>{" "}
                      to reactivate your subscription or email{" "}
                      <span style={{ color: "blue" }}>
                        support@waitsigns.com
                      </span>{" "}
                      for assistance
                    </span>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    </div>
  );
}

export default App;
