import { useEffect, useRef, useState } from "react";
import live_display_socket from "../../config/live_display_socket.js";
import ParkChangeModal from "./ParkChangeModal";
import axios from "../../config/axios";
import TrainAnimation from "./TrainAnimation";
import RespMessage from "./RespMessage";
import HalfTrainAnimation from "./HalfTrainAnimation";
import RideChangeModal from "./RideChangeModal";
import * as Sentry from "@sentry/react";

const park_list = [
  {
    park_name: "Magic Kingdom",
    park_code: "MK",
    background: "/static/backgrounds/MKfinal.png",
  },
  {
    park_name: "Hollywood Studios",
    park_code: "HS",
    background: "/static/backgrounds/HSfinal.png",
  },
  {
    park_name: "Animal Kingdom",
    park_code: "AK",
    background: "/static/backgrounds/AKfinal.png",
  },
  {
    park_name: "Disneyland",
    park_code: "DL",
    background: "/static/backgrounds/DLfinal.png",
  },
  {
    park_name: "California Adventure",
    park_code: "CA",
    background: "/static/backgrounds/CALIfinal.png",
  },
  {
    park_name: "EPCOT",
    park_code: "EPCOT",
    background: "/static/backgrounds/EPfinal.png",
  },
];

const SingleRideWaitTime = ({ device, ride_id, device_id, setCurrentRide }) => {
  const [preliRideData, setPreliRideData] = useState(null);
  const [rideData, setRideData] = useState(null);
  const [park, setPark] = useState(null);
  const [green, setGreen] = useState(false);
  const [showParkChange, setShowParkChange] = useState(false);
  const [showRideChange, setShowRideChange] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const ballChimeRef = useRef(null);
  const [first, setFirst] = useState(true);
  const [parks, setParks] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const trainSoundRef = useRef();

  // handle first load switch to active park
  useEffect(() => {
    if (rideData && firstLoad) {
      setFirstLoad(false);
      if (!rideData.active) {
        axios
          .get(`/parks/public/${device._id}`, { withCredentials: true })
          .then((response) => {
            // console.log('inside first load if: ', response.data.parks);
            if (response.data.parks.length > 0) {
              // console.log('inside first load if: ', response.data.parks);
              const view = "Ride__" + response.data.parks[0].park_code + "_1";
              // console.log('view: ', view);
              setTimeout(() => {
                axios
                  .patch(
                    `/users/device/${device._id}`,
                    { screen_view: view },
                    { withCredentials: true }
                  )
                  .then(() => {
                    live_display_socket.emit("remote_refresh", {
                      device_id: device?._id,
                    });
                  })
                  .catch((error) => {});
              }, 10000);
            }
          })
          .catch((error) => {});
      }
    }
  }, [rideData]);

  useEffect(() => {
    if (park) {
      if (ballChimeRef && first) {
        setFirst(false);
      } else {
        setGreen(false);
        // setRefresh(Math.random());

        setTimeout(() => {
          ballChimeRef.current.volume = localStorage.getItem("volume");
          try {
            ballChimeRef.current.play();
          } catch (error) {
            Sentry.captureException(error);
          }

          setGreen(true);
        }, 5000);
      }
    } else {
      // setGreen(true); todo: if normal animation if fucked fix here
      setGreen(false);

      setTimeout(() => {
        if (ballChimeRef) {
          ballChimeRef.current.volume = localStorage.getItem("volume");
          try {
            ballChimeRef.current.play();
          } catch (error) {
            Sentry.captureException(error);
          }
        }
        setGreen(true);
      }, 7000);
    }
  }, [park, ballChimeRef]);

  useEffect(() => {
    try {
      if (preliRideData) {
        const park_code = preliRideData._id.split("_")[0];

        const newPark = park_list.find((park) => park.park_code === park_code);

        if (newPark.park_name !== park && park !== null) {
          // console.log('PARK CHANGE >>>>>>>>>>>>');
          setTimeout(() => {
            setRideData(preliRideData);
          }, 3000);
        } else {
          // console.log('no park change. setting ride data instantly', preliRideData.name);
          setRideData(preliRideData);
        }

        if (newPark.park_name !== park && park !== null) {
          setRefresh(Math.random());
          setTimeout(() => {
            setPark(newPark.park_name);
          }, 3000);
        } else {
          setPark(newPark.park_name);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [preliRideData]);

  useEffect(() => {
    setCurrentRide(ride_id);
    // console.log('subscribing to ride data: ', ride_id);
    live_display_socket.emit("wait_time_subscribe", { ride_id, device_id });

    return () => {
      live_display_socket.emit("wait_time_unsubscribe", { device_id });
    };
  }, [ride_id]);

  // GET ride data websocket
  useEffect(() => {
    live_display_socket.on("wait_time_update", (data) => {
      // console.log('Got Updated data via WebSocket');
      // console.log(data, ' WS');
      // This is disabled because using both websocket and rest API was causing race conditions
      // since the REST API is the faster of the two and more reliable, we can disable the WS
      // setPreliRideData(data);
    });
  }, []);

  const getRideData = async (ride_id) => {
    try {
      axios.get(`/rides/${ride_id}?device_id=${device_id}`).then((response) => {
        setPreliRideData(response.data.ride);
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // GET ride data API
  useEffect(() => {
    getRideData(ride_id);
    const getRideDataInterval = setInterval(() => {
      getRideData(ride_id);
    }, 2500);
    return () => clearInterval(getRideDataInterval);
  }, [ride_id]);

  const cardStyle = {
    position: "absolute",
    width: "35vw",
    left: "20%",
    top: "45%",
    transform: "translateY(-50%)",
  };

  const handleShowParkChange = () => {
    try {
      axios
        .get(`/parks/public/${device._id}`, { withCredentials: true })
        .then((response) => {
          if (response.data.parks.length > 1) {
            setParks(response.data.parks);
            setShowParkChange(true);
          }
        });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const getBackground = () => {
    const bg = park_list.find((item) => item.park_name === park)?.background;
    if (bg) return bg;
    return "/static/backgrounds/MKfinal.png";
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <audio
        src="https://cdn.mywaitserver.live/ball-chime.mp3"
        ref={ballChimeRef}
      ></audio>

      {!rideData && (
        <div
          style={{
            background: "blue",
            position: "absolute",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
          }}
        />
      )}

      <div
        style={{
          backgroundImage: `url("${getBackground()}")`,
          backgroundSize: "cover",
          display: rideData ? "flex" : "none",
          width: "100vw",
          height: "103vh",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "3.25rem",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{ ...cardStyle, zIndex: 10 }}
          onClick={() => setShowRideChange(true)}
        >
          <img
            src="/static/cards/FP1.png"
            alt=""
            style={{
              width: "100%",
            }}
          />

          {/*Ride Name*/}
          <span
            style={{
              position: "absolute",
              transform: "translateX(-50%)",
              left: "50%",
              // top: '12%',
              top: rideData?.name.includes(" ") ? "12%" : "16%",
              textAlign: "center",
              color: "white",
              fontSize: rideData?.name.includes(" ") ? "32px" : "40px",
            }}
          >
            {rideData?.name}
          </span>

          {/*Wait Time*/}
          {rideData && (
            <div
              style={{
                position: "absolute",
                transform: "translateX(-50%)",
                left: "50%",
                top: "50%",
                textAlign: "center",
                color: "black",
                fontSize: "50px",
              }}
            >
              {rideData.active ? (
                <>
                  {rideData.resp_code === 0 ? (
                    <span>{rideData?.wait_time}</span>
                  ) : (
                    <span style={{ fontSize: "20px" }}>
                      {rideData.resp_msg === `Virtual Queue` ? (
                        <h1
                          style={{
                            fontSize: "50px",
                            marginTop: "20px",
                          }}
                        >
                          {rideData.currentGroupStart}
                        </h1>
                      ) : (
                        <RespMessage msg={rideData.resp_msg} />
                      )}
                    </span>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  <span>Park</span>
                  <span>Unavailable</span>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            width: "50vw",
            left: "3%",
            top: "45%",
            transform: "translateY(-50%)",
            zIndex: 9,
          }}
        >
          <img
            src="/static/cardFAN.png"
            alt=""
            style={{
              width: "100%",
            }}
          />
        </div>
        {/*<div style={{...cardStyle, zIndex: 8, rotate: '-20deg'}}>*/}
        {/*    <img src="/static/cards/FP3.png" alt="" style={{*/}
        {/*        width: '100%',*/}
        {/*    }}/>*/}
        {/*</div>*/}
        {/*<div style={{...cardStyle, zIndex: 7, rotate: '-30deg'}}>*/}
        {/*    <img src="/static/cards/FP4.png" alt="" style={{*/}
        {/*        width: '100%',*/}
        {/*    }}/>*/}
        {/*</div>*/}
        {/*<div style={{...cardStyle, zIndex: 6, rotate: '-40deg'}}>*/}
        {/*    <img src="/static/cards/FP5.png" alt="" style={{*/}
        {/*        width: '100%',*/}
        {/*    }}/>*/}
        {/*</div>*/}

        <div
          style={{
            position: "absolute",
            top: "18%",
            right: "10%",
            height: "95vh",
            width: "30vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => handleShowParkChange()}
        >
          <img
            src="/static/reader.png"
            alt=""
            style={{
              height: "100%",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <img
            src="/static/light.png"
            alt=""
            style={{
              position: "absolute",
              height: "100%",
              opacity: green ? 1 : 0,
              transition: "all 0s",
            }}
          />
          {park === "EPCOT" ? (
            <span
              style={{
                zIndex: 20,
                position: "absolute",
                top: "36%",
                fontSize: "55px",
                color: green ? "#44f811" : "#3c3f52",
                textAlign: "center",
                maxWidth: "300px",
                fontFamily: "EPCOT",
              }}
            >
              EPCOT
            </span>
          ) : park === "Magic Kingdom" ? (
            <span
              style={{
                zIndex: 20,
                position: "absolute",
                top: "28%",
                fontSize: "90px",
                color: green ? "#44f811" : "#3c3f52",
                textAlign: "center",
                maxWidth: "300px",
                fontFamily: "MOUSE",
                lineHeight: "70px",
              }}
            >
              Magic Kingdom
            </span>
          ) : park === "Disneyland" ? (
            <span
              style={{
                zIndex: 20,
                position: "absolute",
                top: "30%",
                fontSize: "90px",
                color: green ? "#44f811" : "#3c3f52",
                textAlign: "center",
                maxWidth: "300px",
                fontFamily: "MOUSE",
              }}
            >
              Disneyland
            </span>
          ) : park === "California Adventure" ? (
            <span
              style={{
                zIndex: 20,
                position: "absolute",
                top: "30%",
                fontSize: "50px",
                color: green ? "#44f811" : "#3c3f52",
                textAlign: "center",
                maxWidth: "300px",
                fontFamily: "CA",
                textTransform: "uppercase",
              }}
            >
              California Adventure
            </span>
          ) : park === "Animal Kingdom" ? (
            <span
              style={{
                zIndex: 20,
                position: "absolute",
                top: "30%",
                fontSize: "50px",
                color: green ? "#44f811" : "#3c3f52",
                textAlign: "center",
                maxWidth: "300px",
                fontFamily: "SAFARI",
              }}
            >
              Animal Kingdom
            </span>
          ) : park === "Hollywood Studios" ? (
            <span
              style={{
                zIndex: 20,
                position: "absolute",
                top: "29%",
                fontSize: "60px",
                color: green ? "#44f811" : "#3c3f52",
                textAlign: "center",
                maxWidth: "300px",
                fontFamily: "Anna ITC Std",
              }}
            >
              Hollywood <br /> studios
            </span>
          ) : (
            <span
              style={{
                zIndex: 20,
                position: "absolute",
                top: "32%",
                fontSize: "70px",
                color: green ? "#44f811" : "#3c3f52",
                transition: "all 0s",
                textAlign: "center",
                maxWidth: "300px",
                fontFamily: "MOUSE",
              }}
            >
              {park}
            </span>
          )}
        </div>
      </div>

      {showParkChange && (
        <ParkChangeModal
          device={device}
          setShowParkChange={setShowParkChange}
          parks={parks}
        />
      )}

      {showRideChange && (
        <RideChangeModal
          device={device}
          setShowRideChange={setShowRideChange}
          currentRide={rideData}
        />
      )}

      <audio id="train-audio" ref={trainSoundRef}></audio>

      <TrainAnimation
        refresh={refresh}
        noAnimation={park === null}
        trainSoundRef={trainSoundRef}
      />

      <HalfTrainAnimation
        animation={park === null}
        trainSoundRef={trainSoundRef}
      />
      {/*<MainSpotifyPlayer ride_id={ride_id} />*/}
    </div>
  );
};
export default SingleRideWaitTime;
