import React, { useEffect, useState } from "react";
import axios from "../../config/axios";
import moment from "moment";
import { useConfig } from "../../hooks/useConfig";
const Stream = ({ device }) => {
  const { config } = useConfig();

  const [loading, setLoading] = useState(true);
  const [showStreamLoader, setShowStreamLoader] = useState(false);
  const [showParks, setShowParks] = useState(false);
  const [showTimes, setShowTimes] = useState([]);
  const tz = "America/New_York";
  const [showStream, setShowStream] = useState(null);
  const [offStreamVideo, setOffStreamVideo] = useState("stream-off-vid.mp4"); // NEW
  // const shouldShowTimes = offStreamVideo === 'stream-off-vid.mp4';

  useEffect(() => {
    if (showStream) {
      setShowStreamLoader(true);
      setTimeout(() => {
        setShowStreamLoader(false);
      }, 10000);
    }
  }, [showStream]);

  // get show time
  const getShowTime = () => {
    axios
      .get("/shows", { withCredentials: true })
      .then((response) => {
        setLoading(false);
        const sortedShows = response.data.shows.sort((a, b) => {
          if (a._id === "SHOW_1") return -1;
          if (b._id === "SHOW_1") return 1;
          return 0;
        });
        setShowTimes(sortedShows);
      })
      .catch((error) => {
        // console.log('error', error);
      });
  };

  useEffect(() => {
    getShowTime();
  }, []);

  const timeLogic = () => {
    const currentTime = moment().tz("America/New_York"); // EST timezone
    // set show stream to true if current time is between 8:00PM-10:30PM
    if (
      currentTime.format("HH:mm") >= config.terraceOpenTime &&
      currentTime.format("HH:mm") <= config.terraceCloseTime
    ) {
      setShowStream(true);
    } else if (
      currentTime.format("HH:mm") > config.terraceCloseTime &&
      currentTime.format("HH:mm") <= config.fWendedvideo
    ) {
      // NEW
      setShowStream(false); // NEW
      setOffStreamVideo("FWended.mp4"); // NEW
    } else {
      // NEW
      setShowStream(false); // NEW
      setOffStreamVideo("stream-off-vid.mp4"); // NEW
    }

    // if current minute is 0, get show time
    if (currentTime.format("mm") === "00") {
      getShowTime();
    }
  };

  //time tracker
  useEffect(() => {
    // Check every minute
    const intervalId = setInterval(() => {
      timeLogic();
    }, 60000); // 60000ms = 1 minute
    timeLogic();
    return () => clearInterval(intervalId);
  }, [config]);

  const getShowTimeText = () => {
    if (showTimes.length > 0) {
      if (showTimes[0].startTime === null && showTimes[1].startTime === null) {
        return "No Shows Tonight";
      }

      if (showTimes[0].startTime === null && showTimes[1].startTime !== null) {
        return `EPCOT ${moment(showTimes[1].startTime)
          .tz(tz)
          .format("LT")} (EST) / Magic Kingdom ${config.mkFWManual}`;
      }

      if (showTimes[0].startTime !== null && showTimes[1].startTime === null) {
        return `Magic Kingdom ${moment(showTimes[0].startTime)
          .tz(tz)
          .format("LT")} (EST)`;
      }

      if (showTimes[0].startTime !== null && showTimes[1].startTime !== null) {
        return `EPCOT ${moment(showTimes[1].startTime)
          .tz(tz)
          .format("LT")} (EST) / Magic Kingdom ${moment(showTimes[0].startTime)
          .tz(tz)
          .format("LT")} (EST)`;
      }
    }
  };

  const getEpcotShowTimeText = () => {
    if (showTimes.length > 0) {
      if (showTimes[1].startTime === null) {
        return "No Show";
      }
      return moment(showTimes[1].startTime).tz(tz).format("LT") + " (EST)";
    }
  };

  const getMagicKingdomShowTimeText = () => {
    if (showTimes.length > 0) {
      if (showTimes[0].startTime === null) {
        return config.mkFWManual;
      }
      return moment(showTimes[0].startTime).tz(tz).format("LT") + " (EST)";
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            fontSize: "30px",
            color: "blue",
            fontFamily: "Lato",
            zIndex: 50,
          }}
        >
          <span>Loading...</span>
        </div>
      ) : (
        <>
          {showStream === true ? (
            <>
              <div
                style={{
                  position: "absolute",
                  top: showStreamLoader ? 0 : "-100vh",
                  transition: showStreamLoader ? "none" : "top 3s",
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  zIndex: 60,
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    width: "100vw",
                    height: "100vh",
                    objectFit: "cover",
                  }}
                  src="/static/curtainRaiseUSE.jpg"
                  alt=""
                />
              </div>

              <div
                style={{
                  height: "100px",
                  background: "#12243F",
                  width: "100vw",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  zIndex: 20,
                  fontFamily: "Lato",
                }}
              >
                <span
                  style={{
                    fontSize: "30px",
                  }}
                >
                  NEXT FIREWORKS SHOW: {getShowTimeText()}
                </span>
              </div>

              <iframe
                title="Stream"
                style={{
                  width: "230vw",
                  height: "100vw",
                  zIndex: 10,
                  position: "absolute",
                  top: 180,
                  right: -190,
                  transform: "rotate(-6deg) scale(1.4)",
                }}
                src={
                  config.streamUrl === 1
                    ? "https://live2.mywaitserver.com/live/player"
                    : "https://live.mywaitserver.com/live/player"
                }
                allowFullScreen=""
                frameBorder="0"
              />

              <img
                src="/static/stream/livelogo.png"
                alt=""
                style={{
                  position: "absolute",
                  top: "100px",
                  right: "0px",
                  width: "340px",
                  zIndex: 20,
                }}
              />

              <img
                src={
                  showParks
                    ? "/static/stream/pin%20BUTTON%20pressed.png"
                    : "/static/stream/pin%20BUTTON%20not%20pressed.png"
                }
                alt=""
                style={{
                  position: "absolute",
                  bottom: "90px",
                  left: "0px",
                  width: "150px",
                  zIndex: 30,
                }}
                onClick={() => setShowParks(!showParks)}
              />

              <img
                src="/static/stream/balcony.png"
                alt=""
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100vw",
                  zIndex: 20,
                }}
              />

              {showParks && (
                <>
                  <img
                    src="/static/stream/pin%20map.png"
                    alt=""
                    style={{
                      position: "absolute",
                      top: "310px",
                      left: "200px",
                      width: "120px",
                      zIndex: 20,
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "350px",
                      left: "110px",
                      width: "fit-content",
                      zIndex: 20,
                      backgroundColor: "white",
                      padding: "0px 20px",
                      border: "4px solid #12243F",
                      fontFamily: "Lato",
                      fontWeight: "800",
                      fontSize: "25px",
                      borderRadius: "10px",
                    }}
                  >
                    EPCOT
                  </div>

                  <img
                    src="/static/stream/pin%20map.png"
                    alt=""
                    style={{
                      position: "absolute",
                      top: "335px",
                      left: "285px",
                      width: "120px",
                      zIndex: 20,
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "380px",
                      left: "370px",
                      width: "fit-content",
                      zIndex: 20,
                      backgroundColor: "white",
                      padding: "0px 20px",
                      border: "4px solid #12243F",
                      fontFamily: "Lato",
                      fontWeight: "800",
                      fontSize: "25px",
                      borderRadius: "10px",
                    }}
                  >
                    Disney Springs
                  </div>

                  <img
                    src="/static/stream/pin%20map.png"
                    alt=""
                    style={{
                      position: "absolute",
                      top: "300px",
                      right: "75px",
                      width: "120px",
                      zIndex: 20,
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "340px",
                      right: "165px",
                      width: "fit-content",
                      zIndex: 20,
                      backgroundColor: "white",
                      padding: "0px 20px",
                      border: "4px solid #12243F",
                      fontFamily: "Lato",
                      fontWeight: "800",
                      fontSize: "25px",
                      borderRadius: "10px",
                    }}
                  >
                    Magic Kingdom
                  </div>
                </>
              )}
            </>
          ) : (
            showStream === false && (
              <div
                style={{
                  position: "relative",
                  width: "100vw",
                  height: "100vh",
                }}
              >
                <video
                  src={`https://cdn.mywaitserver.live/${offStreamVideo}`} // NEW
                  autoPlay={true}
                  muted={true}
                  loop={true}
                />
                {offStreamVideo === "stream-off-vid.mp4" && (
                  <>
                    <span
                      style={{
                        zIndex: 40,
                        position: "absolute",
                        top: "37%",
                        left: "55%",
                        fontSize: 50,
                        fontFamily: "IMPACT",
                        fontStyle: "normal",
                        opacity: showStream === false ? 1 : 0,
                        transition: "all 2s",
                      }}
                    >
                      {getMagicKingdomShowTimeText()}
                    </span>

                    <span
                      style={{
                        zIndex: 40,
                        position: "absolute",
                        top: "45%",
                        left: "48%",
                        fontSize: 50,
                        fontFamily: "IMPACT",
                        fontStyle: "normal",
                        opacity: showStream === false ? 1 : 0,
                        transition: "all 2s",
                      }}
                    >
                      {getEpcotShowTimeText()}
                    </span>

                    <span
                      style={{
                        zIndex: 40,
                        position: "absolute",
                        top: "54%",
                        left: "56%",
                        fontSize: 50,
                        fontFamily: "IMPACT",
                        color: "#0152CA",
                        fontStyle: "italic",
                        opacity: showStream === false ? 1 : 0,
                        transition: "all 2s",
                      }}
                    >
                      {moment(config.terraceOpenTime, "HH:mm").format("LT")}{" "}
                      (EST)
                    </span>
                  </>
                )}
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};

export default Stream;
