import { io } from "socket.io-client";
import live_display_socket from "./live_display_socket";
import { serverUrl } from "./serverConfig";

let connection_socket;

connection_socket = io(`${serverUrl}/display-connection`, {
  transports: ["websocket"],
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
});
// connection_socket = io('http://localhost:5000/display-connection');

export default connection_socket;
