import moment from 'moment-timezone';
import { useEffect, useRef, useState } from "react";

const VacationCountdown = ({
                               countDownDate,
                               currentDate,
                               timezone,
                               device,
                               last_name
                           }) => {
    const getDaysLeft = () => {
        const countdownEnd = moment(countDownDate).tz(timezone);
        const current = moment(currentDate).tz(timezone);

        let dl = countdownEnd.diff(current, 'days');
        if (dl < 0) {
            dl = 0
        }
        return dl;
    };

    const [showLastName, setShowLastName] = useState(false);
    const [showDaysLeft, setShowDaysLeft] = useState(false);
    const [firstPlay, setFirstPlay] = useState(true);
    const loopVidRef = useRef(null);

    useEffect(() => {
        const video = document.getElementById('vc-video');
        video.volume = localStorage.getItem('volume') || 0.3;
        video.play();

        const interval = setInterval(() => {
            const currentTime = video.currentTime;

            if (currentTime >= 2 && currentTime < 6) {
                setShowLastName(true);
                setShowDaysLeft(false);
            } else if (currentTime >= 7 && currentTime < 20) {
                setShowLastName(false);
                setShowDaysLeft(true);
            } else {
                setShowLastName(false);
                setShowDaysLeft(false);
            }

            if (currentTime > 19) {
                // console.log('first play end');
                setFirstPlay(false);
                clearInterval(interval);
            }
        }, 100); // Check every 100ms

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        if (loopVidRef.current) {
            const video = document.getElementById('loop-video');
            video.volume = localStorage.getItem('volume') || 0.3;
            video.play();
        }
    }, [loopVidRef]);

    return (
        <div style={{width: '100vw', height: '100vh', position: 'relative'}}>

            {
                firstPlay ? <>
                	<span style={{
                        fontSize: '300px',
                        color: 'white',
                        position: 'absolute',
                        top: '15%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 100,
                        fontFamily: 'MOUSE',
                        lineHeight: 0,
                        opacity: showLastName ? 1 : 0,
                        transition: 'opacity 0.5s',
                        textShadow: '4px 4px 45px rgba(9,90,202,1)'
                    }}>{last_name}</span>


                        {countDownDate && timezone && (
                            <>
                                {getDaysLeft() >= 0 ? (
                                    <span style={{
                                        fontSize: '300px',
                                        color: 'white',
                                        position: 'absolute',
                                        top: '10%',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        zIndex: 100,
                                        fontFamily: 'MOUSE',
                                        lineHeight: 1,
                                        opacity: showDaysLeft ? 1 : 0,
                                        transition: 'opacity 0.5s',
                                        textShadow: '4px 4px 45px rgba(9,90,202,1)'
                                    }}>{getDaysLeft()}</span>
                                ) : (
                                    <span></span>
                                )}
                            </>
                        )}

                        <video id={'vc-video'} src="https://cdn.mywaitserver.live/cd-1.mp4"
                               style={{width: '100vw', height: '100vh', objectFit: "cover"}}></video>

                    </>
                    :
                    <>
                        {countDownDate && timezone && (
                            <>
                                {getDaysLeft() >= 0 ? (
                                    <span style={{
                                        fontSize: '300px',
                                        color: 'white',
                                        position: 'absolute',
                                        top: '10%',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        zIndex: 100,
                                        fontFamily: 'MOUSE',
                                        lineHeight: 1,
                                        opacity: showDaysLeft ? 1 : 0,
                                        transition: 'opacity 0.5s',
                                        textShadow: '4px 4px 45px rgba(9,90,202,1)'
                                    }}>{getDaysLeft()}</span>
                                ) : (
                                    <span></span>
                                )}
                            </>
                        )}
                        <video loop={true} muted={true} autoPlay={true} ref={loopVidRef} id={'loop-video'}
                               src="https://cdn.mywaitserver.live/cd-2.mp4"
                               style={{width: '100vw', height: '100vh', objectFit: "cover"}}></video>
                    </>
            }

        </div>
    );
};

export default VacationCountdown;

