import { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../config/firebaseConfig";
import * as Sentry from "@sentry/react";

export const useConfig = () => {
  const [config, setConfig] = useState({});

  useEffect(() => {
    try {
      const configRef = ref(database, `config`);
      onValue(
        configRef,
        (snapshot) => {
          if (snapshot.exists()) {
            setConfig(snapshot.val());
          } else {
            Sentry.captureMessage("No data available in Firebase config");
          }
        },
        (error) => {
          Sentry.captureException(error);
        }
      );
    } catch (err) {
      Sentry.captureException(err);
    }
  }, []);

  return {
    config,
  };
};
