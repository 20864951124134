import { io } from "socket.io-client";
import { serverUrl } from "./serverConfig";

let live_display_socket;

live_display_socket = io(`${serverUrl}/live-display`, {
  transports: ["websocket"],
});

export default live_display_socket;
